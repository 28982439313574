import mgrenier from "../../../../src/business-cases/transports-mds/mgrenier@2x.jpg";
import fretlinkProcess from "../../../../src/business-cases/transports-mds/fretlink-process.png";
import activityZones from "../../../../src/business-cases/transports-mds/activity-zones.png";
import offers from "../../../../src/business-cases/transports-mds/offers.en.png";
import * as React from 'react';
export default {
  mgrenier,
  fretlinkProcess,
  activityZones,
  offers,
  React
};