import carteDeFrance from "../../../../src/business-cases/nestle-purina/carte_france_zoom_idf.png";
import renaud from "../../../../src/business-cases/nestle-purina/renaud.jpg";
import claire from "../../../../src/business-cases/nestle-purina/claire.jpg";
import expeditions from "../../../../src/business-cases/nestle-purina/expeditions.png";
import fluxDeTransport from "../../../../src/business-cases/nestle-purina/priorisation_des_lignes.png";
import factory from "../../../../src/business-cases/nestle-purina/usine.jpg";
import etapesLivraison from "../../../../src/business-cases/nestle-purina/etapes_livraison.png";
import depot from "../../../../src/business-cases/nestle-purina/entrepot.jpg";
import reporting from "../../../../src/business-cases/nestle-purina/reporting.png";
import * as React from 'react';
export default {
  carteDeFrance,
  renaud,
  claire,
  expeditions,
  fluxDeTransport,
  factory,
  etapesLivraison,
  depot,
  reporting,
  React
};