import cdarma from "../../../../src/business-cases/transports-caudron/cdarma@1x.png";
import mreis from "../../../../src/business-cases/transports-caudron/mreis@1x.png";
import caveatsSpeed from "../../../../src/business-cases/transports-caudron/caveats-speed.png";
import activityZones from "../../../../src/business-cases/transports-caudron/activity-zones.png";
import fleetDescription from "../../../../src/business-cases/transports-caudron/fleet-description.png";
import caveatsPlanning from "../../../../src/business-cases/transports-caudron/caveats-planning.png";
import caveatsProfits from "../../../../src/business-cases/transports-caudron/caveats-profits.png";
import offers from "../../../../src/business-cases/transports-caudron/offers.png";
import reporting from "../../../../src/business-cases/transports-caudron/reporting.png";
import * as React from 'react';
export default {
  cdarma,
  mreis,
  caveatsSpeed,
  activityZones,
  fleetDescription,
  caveatsPlanning,
  caveatsProfits,
  offers,
  reporting,
  React
};