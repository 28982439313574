import activityZones from "../../../../src/business-cases/lys-transport/activity-zones.png";
import fxlebot from "../../../../src/business-cases/lys-transport/fxlebot@2x.jpg";
import mreis from "../../../../src/business-cases/lys-transport/mreis@2x.jpg";
import transportOffers from "../../../../src/business-cases/lys-transport/offers.en.png";
import * as React from 'react';
export default {
  activityZones,
  fxlebot,
  mreis,
  transportOffers,
  React
};