import activityZones from "../../../../src/business-cases/lys-transport/activity-zones.png";
import fretlinkProcess from "../../../../src/business-cases/lys-transport/fretlink-process.png";
import fxlebot from "../../../../src/business-cases/lys-transport/fxlebot@2x.jpg";
import mreis from "../../../../src/business-cases/lys-transport/mreis@2x.jpg";
import offers from "../../../../src/business-cases/lys-transport/offers.fr.png";
import * as React from 'react';
export default {
  activityZones,
  fretlinkProcess,
  fxlebot,
  mreis,
  offers,
  React
};