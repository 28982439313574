// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-business-case-layout-js": () => import("./../../../src/components/BusinessCaseLayout.js" /* webpackChunkName: "component---src-components-business-case-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-business-cases-js": () => import("./../../../src/pages/business-cases.js" /* webpackChunkName: "component---src-pages-business-cases-js" */),
  "component---src-pages-carriers-js": () => import("./../../../src/pages/carriers.js" /* webpackChunkName: "component---src-pages-carriers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-green-roads-js": () => import("./../../../src/pages/green-roads.js" /* webpackChunkName: "component---src-pages-green-roads-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-lost-password-js": () => import("./../../../src/pages/lost-password.js" /* webpackChunkName: "component---src-pages-lost-password-js" */),
  "component---src-pages-shippers-js": () => import("./../../../src/pages/shippers.js" /* webpackChunkName: "component---src-pages-shippers-js" */),
  "component---src-pages-sign-up-carrier-js": () => import("./../../../src/pages/sign-up/carrier.js" /* webpackChunkName: "component---src-pages-sign-up-carrier-js" */),
  "component---src-pages-sign-up-index-js": () => import("./../../../src/pages/sign-up/index.js" /* webpackChunkName: "component---src-pages-sign-up-index-js" */),
  "component---src-pages-sign-up-shipper-js": () => import("./../../../src/pages/sign-up/shipper.js" /* webpackChunkName: "component---src-pages-sign-up-shipper-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-transport-operating-system-js": () => import("./../../../src/pages/transport-operating-system.js" /* webpackChunkName: "component---src-pages-transport-operating-system-js" */)
}

