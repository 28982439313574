import activityZones from "../../../../src/business-cases/kevel-transports/activity-zones.png";
import cdarma from "../../../../src/business-cases/kevel-transports/cdarma@2x.jpg";
import mreis from "../../../../src/business-cases/kevel-transports/mreis@2x.jpg";
import transportOffers from "../../../../src/business-cases/kevel-transports/offers.en.png";
import * as React from 'react';
export default {
  activityZones,
  cdarma,
  mreis,
  transportOffers,
  React
};