import zoneActivites from "../../../../src/business-cases/kevel-transports/activity-zones.png";
import cdarma from "../../../../src/business-cases/kevel-transports/cdarma@2x.jpg";
import mreis from "../../../../src/business-cases/kevel-transports/mreis@2x.jpg";
import offers from "../../../../src/business-cases/kevel-transports/offers.fr.png";
import * as React from 'react';
export default {
  zoneActivites,
  cdarma,
  mreis,
  offers,
  React
};