module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fretlink","short_name":"Fretlink","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"browser","icon":"src/images/fretlink-icon.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f96c7c84c42ae3a685c4e884d47d4eee"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/ubuntu/builds/homepage/frontend","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","fr"],"defaultLanguage":"fr","generateDefaultLanguagePage":true,"fallbackLng":"fr","siteUrl":"https://www.fretlink.com/","redirect":true,"i18nextOptions":{"interpolation":{"escapeValue":false}},"pages":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-83916888-1"],"anonymize_ip":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
